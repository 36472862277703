@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&family=PT+Serif:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  font-size: 62.5%;
}

:root {
  --background: #020202;
  --titleText: #081f5c;
  --primaryAccent: #24b0ba;
  --secondaryAccent: #7123e9;
  /* EAB308 */
}

#nav-icon3{
  width: 2rem;
  height: 2rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span{
  display: block;
  position: absolute;
  height: 0.2rem;
  width: 100%;
  background: var(--titleText);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0rem;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 0.8rem;
}

#nav-icon3 span:nth-child(4) {
  top: 1.6rem;
}

#nav-icon3.open span:nth-child(1) {
  top: 1.8rem;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.img{
  background-image: url("/public/img.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }
}